import styled from '@emotion/styled'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useRecoilState } from 'recoil'
import { userAtom } from '../../utils/State/UserState'
import { Color } from '../../utils/Theme'
import { NormalLogo } from '../Logo'

import HomeIcon from '@mui/icons-material/Home'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import SettingsIcon from '@mui/icons-material/Settings'
import { useLocation, useNavigate } from 'react-router'

export const Sidebar = () => {
  const [_user] = useRecoilState(userAtom)

  // const location = useLocation()
  const navi = useNavigate()
  const path = useLocation().pathname.split('/')[1]

  const menu = [
    {
      link: 'group',
      icon: <HomeIcon />,
      title: 'グループ',
    },
    {
      link: 'notify',
      icon: <NotificationsNoneIcon />,
      title: 'お知らせ',
    },
    {
      link: 'setting',
      icon: <SettingsIcon />,
      title: '設定',
    },
  ]

  const changePage = (link: string) => {
    navi(`/${link}`)
  }

  return (
    <Root>
      <List
        sx={{
          width: '260px',
          padding: 0,
        }}>
        <ListItem
          sx={{
            marginTop: '65px',
          }}>
          <NormalLogo onClick={() => ''} />
        </ListItem>
        <SListItem>
          <Mail>{_user.email}</Mail>
        </SListItem>
        <ListWrapper>
          {menu.map((m) => (
            <LIWrapper
              key={m.title}
              icp={String(path === m.link)}
              onClick={() => changePage(m.link)}>
              <ListItem button>
                <SListItemIcon icp={String(path === m.link)}>
                  {m.icon}
                </SListItemIcon>
                <SListItemText
                  primary={m.title}
                  icp={String(path === m.link)}
                />
              </ListItem>
            </LIWrapper>
          ))}
        </ListWrapper>
      </List>
    </Root>
  )
}

const Mail = styled('div')({
  fontSize: '14px',
})

const SListItem = styled('div')({
  position: 'absolute',
  left: '39px',
  margin: '0',
  marginRight: '39px',
  padding: '0',
})

// icp : is current page
const LIWrapper = styled('div')((p: { icp: string }) => ({
  background: p.icp === 'true' ? Color.primary : Color.secondary,
  display: 'flex',
}))

const ListWrapper = styled('div')({
  marginTop: '83.5px',
})

const SListItemIcon = styled(ListItemIcon)((p: { icp: string }) => ({
  margin: '2.5px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > svg': {
    height: '36px',
    width: '36px',
    color: p.icp === 'true' ? 'white' : Color.black,
  },
}))

const SListItemText = styled(ListItemText)((p: { icp: string }) => ({
  '& > *': {
    fontSize: '17px !important',
    fontWeight: p.icp === 'true' ? 400 : 700,
    color: p.icp === 'true' ? 'white' : Color.black,
  },
}))

const Root = styled('div')({
  background: Color.secondary,
  height: '100vh',
  position: 'fixed',
})
