import { atom } from 'recoil'

type User = {
  email: string
}

export const userAtom = atom<User>({
  key: 'currentUser',
  default: {
    email: '',
  },
})
