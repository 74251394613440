import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { Sidebar } from '../components/Sidebar'
const Layout: React.VFC<{ page: ReactNode }> = ({ page }) => {
  return (
    <div>
      <Wrapper>
        <Sidebar />
        <Contents>{page}</Contents>
      </Wrapper>
    </div>
  )
}

export default Layout

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

const Contents = styled('div')({
  width: 'calc(100vw - 260px - 144px)',
  marginLeft: '260px',
})
