import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import AuthRouter from './AuthRouter'

const Login = React.lazy(() => import('../../pages/Login'))

const Router: React.VFC = () => {
  return (
    <React.Suspense fallback={<div>LOADING...</div>}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<AuthRouter />} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </React.Suspense>
  )
}

export default Router
