import { onAuthStateChanged } from 'firebase/auth'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { auth } from '../../libs/Firebase'
import Layout from '../Layout'

import { userAtom } from '../State/UserState'

const Home = React.lazy(() => import('../../pages/Home'))
const Notify = React.lazy(() => import('../../pages/Notify'))
const NotifyDetail = React.lazy(() => import('../../pages/Notify/Detail'))
const NotifyCreate = React.lazy(() => import('../../pages/Notify/Create'))
const Setting = React.lazy(() => import('../../pages/Setting'))
const Detail = React.lazy(() => import('../../pages/Home/Detail'))
const History = React.lazy(() => import('../../pages/Home/History'))

const AuthRouter: React.VFC = () => {
  const navi = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_user, setUser] = useRecoilState(userAtom)

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        console.log('u', u)
        setUser({ email: u.email || '' })
      } else {
        console.log('not signin')
        navi('/login')
      }
    })
  }, [navi, setUser])

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/group" replace />} />
      <Route path="/group" element={<Layout page={<Home />} />} />
      <Route path="/notify" element={<Layout page={<Notify />} />} />
      <Route
        path="/notify/create"
        element={<Layout page={<NotifyCreate />} />}
      />
      <Route path="/notify/:id" element={<Layout page={<NotifyDetail />} />} />
      <Route path="/group/:id" element={<Layout page={<Detail />} />} />
      <Route
        path="/group/:id/histories"
        element={<Layout page={<History />} />}
      />
      <Route path="/group/:id" element={<Layout page={<Detail />} />} />
      <Route path="/setting" element={<Layout page={<Setting />} />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AuthRouter
