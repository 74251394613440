import styled from '@emotion/styled'

import { ReactComponent as SVG } from './logo.svg'

export interface Props {
  onClick?: () => void
}

export const NormalLogo = ({ onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <SVG />
    </Wrapper>
  )
}

const Wrapper = styled('span')({
  cursor: 'pointer',
  '& > svg': {
    height: '64px',
  },
})
