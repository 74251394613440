import { getApps, initializeApp } from 'firebase/app'
import 'firebase/auth'
import {
  getAuth,
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  connectAuthEmulator,
} from 'firebase/auth'

import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

// initializeApp(firebaseConfig)

const apps = getApps()
const firebase = apps.length ? apps[0] : initializeApp(firebaseConfig)

export const db = getFirestore(firebase)
export const auth = getAuth()

console.log('env', process.env.REACT_APP_ENVIRONMENT)
if (process.env.REACT_APP_ENVIRONMENT === 'TEST') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080)
}

export const configureCaptcha = (): RecaptchaVerifier => {
  const recaptchaVerifier = new RecaptchaVerifier(
    'sign-in-button',
    {
      size: 'invisible',
      callback: (response: any) => {
        console.log('Recaptcha verified', response)
      },
    },
    auth
  )
  return recaptchaVerifier
}
export const LoginWithMail = (
  mail: string,
  pass: string
): Promise<UserCredential> => {
  return signInWithEmailAndPassword(auth, mail, pass)
}

export const sendOneTimePass = (
  resolver: MultiFactorResolver,
  recapture: RecaptchaVerifier
): Promise<string> => {
  const phoneOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  }
  const phoneAuthProvider = new PhoneAuthProvider(auth)
  return phoneAuthProvider.verifyPhoneNumber(phoneOptions, recapture)
}

export const Logout = (): Promise<any> => {
  return signOut(auth)
}

export const getResolver = (error: any): MultiFactorResolver => {
  return getMultiFactorResolver(auth, error)
}
