import { createTheme } from '@mui/material/styles'

export enum Color {
  primary = '#4052EC',
  secondary = '#F6F8FC',
  warning = '#FFD323',
  lightgray = '#F8F8F8',
  gray = '#5B5B5B',
  black = '#171717',
  white = '#FFFFFF',
  border = '#CDD2DF',
  hover = '#838791',
}

export type Pallet =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | undefined

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: Color.white,
      paper: Color.secondary,
    },
    primary: {
      main: Color.primary,
      contrastText: Color.white,
    },
    secondary: {
      main: Color.secondary,
      contrastText: Color.black,
    },
    warning: {
      main: Color.warning,
      contrastText: Color.black,
    },
    info: {
      main: Color.lightgray,
      contrastText: Color.gray,
    },
  },
  typography: {
    fontFamily: `'Noto Sans JP', sans-serif`,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
})
